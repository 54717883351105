<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>جميع المشروعــات</h3></div>
    <div class="container">
      <v-flex>
        <v-row v-if="allProjects.length >= 1">
          <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mt-10">
            <v-toolbar color="indigo" dark>
              <v-toolbar-title>
                أهم المشروعات الحاليه بالشركة
              </v-toolbar-title>
            </v-toolbar>
            <div class="transaction pa-5">
              <div class="head">
                <div class="row">
                  <div class="col-2 " justify="center">الصوره</div>
                  <div class="col-2">تاريخ انجاز المشروع</div>
                  <div class="col-8 ">أسم المشروع</div>
                </div>
                <div class="items">
                  <div v-for="(selectNew, i) in selectFourProjects" :key="i">
                    <router-link
                      class="row routerStyle"
                      :to="{
                        name: 'ShowMore',
                        params: { userId: selectNew.slug },
                      }"
                    >
                      <div
                        class="col-2 linkStyle"
                        v-for="(elem, index) in selectNew.project_images"
                        :key="elem"
                        v-bind:selected="index == 1"
                        v-show="elem.main_image == 1"
                      >
                        <v-img
                          width="100"
                          height="100"
                          :src="
                            'https://backend.ascww.org/api/projects/image/' +
                              elem.path
                          "
                        />
                      </div>
                      <div class="col-2 linkStyle mt-10">
                        {{ selectNew.created_at.substring(0, 10) }}
                      </div>
                      <div class="col-8 linkStyle mt-10">
                        {{ selectNew.title }}
                      </div>
                    </router-link>
                    <hr
                      class="grey--text"
                      v-if="!(i == allProjects.length - 1)"
                    />
                  </div>
                  <div class="text-center" v-show="allProjects.length > 3">
                    <v-pagination
                      v-model="page"
                      :length="lengthPigination"
                      prev-icon="mdi-menu-left"
                      next-icon="mdi-menu-right"
                    ></v-pagination>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row v-else
          ><v-img
            :src="loadingLink"
            width="200px"
            height="200px"
            contain
            class="loadImgClass"
          ></v-img
        ></v-row>
      </v-flex>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loadingLink: require("@/assets/Images/load.svg"),
      page: 1,
    };
  },
  methods: {},
  computed: {
    allProjects() {
      return this.$store.state.projects;
    },
    lengthPigination() {
      const x = this.allProjects.length % 3;
      if (x) {
        return this.allProjects.length / 3 + 1;
      } else return this.allProjects.length / 3;
    },
    selectFourProjects() {
      if (this.allProjects.length - 3 * this.page < 0) {
        return this.allProjects.slice(0, 2);
      } else {
        return this.allProjects.slice(
          this.allProjects.length - 3 * this.page,
          this.allProjects.length - (this.page - 1) * 3
        );
      }
    },
  },
  created() {
    this.$store.dispatch("findAllProjects");
  },
  mounted: {},
};
</script>
<style>
.imgClosed {
  margin-right: 200px;
}
.linkTender {
  text-decoration: none;
  color: indigo !important;
  font-weight: bold;
}
.linkStyle {
  text-decoration: none;
  color: black;
}
.routerStyle {
  margin: 1px;
  text-decoration: none;
}
.routerStyle:hover {
  background: rgba(128, 128, 128, 0.158);
}
</style>
